import React from 'react';
import FakeLoader from './FakeLoader';

const InputFakeLoader = ({ children, loading }) => {
  return (
    <div className='position-relative'>
      {children || null}
      {loading && (
        <FakeLoader size="lg" loading={loading} />
      )}
    </div>
  );
};

export default InputFakeLoader;
