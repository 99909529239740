import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import {
  ICON_FOLDER_OPEN,
  ICON_LINK,
  ICON_UPLOAD
} from '@apprentage/constants';
import {
  FaCode,
  FaCog,
  FaFileAlt,
  FaPhotoVideo,
  FaTags,
  FaToolbox
} from 'react-icons/fa';
import { FaMagnifyingGlassChart } from 'react-icons/fa6';
import ReactTooltip from 'react-tooltip';
import { useFlags, useFlagsmith } from 'flagsmith/react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FLAG_RESOURCES_READONLY,
  NOTICE_RESOURCES_READONLY_TITLE
} from '../../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../actions/FeatureFlags';
import {
  getResourceGroup,
  getResourceGroups,
  resetResourceGroup
} from '../../../actions/ResourceGroups';
import { setCurrentModal } from '../../../actions/Modals';
import { withAuthorization } from '../../Session';
import {
  ORG_CONSOLE_DASHBOARD,
  ORG_CONSOLE_RESOURCES
} from '../../../constants/routes';
import {
  canCreateResource,
  canCreateResourceGroup,
  canDeleteResourceGroup,
  canEditResourceGroup,
  canManageResourceGroups
} from '../../../services/currentUser';
import {
  getResourceGroupTags,
  getResourcesByParentId,
  setResourceGroupFiles
} from '../../../actions/Resources';
import { fetchResourceGroup } from '../../../services/resourceGroups';
import { fetchResource } from '../../../services/resources';
import { entryOrgMatchesUserOrg } from '../../../services/organizations';
import {
  MODAL_KEY_MANAGE_CDN_FILE,
  MODAL_KEY_RESOURCE_PREVIEW,
  MODAL_KEY_UPLOAD_FILES
} from '../../../constants/modals';
import {
  SHEET_KEY_MATERIAL, SHEET_KEY_MATERIAL_ADD, SHEET_KEY_RESUME_SCREENER
} from '../../../constants/sideSheets';
import { setSideSheet } from '../../../actions/SideSheets';
import { getResourceMaterial } from '../../../actions/Materials';
import withOrgConsole from '../../App/withOrgConsole';
import DeleteResourceGroup from '../../ManageContent/DeleteContent/DeleteResourceGroup';
import Loading from '../../Loading';
import GroupsList from './List';
import CdnFiles from '../../ManageContent/CdnFiles';
import GroupTagFilters from './GroupTagFilters';
import Breadcrumb from './Breadcrumb';
import EmptyData from '../../ManageContent/EmptyData';
import OrgConsoleHeader from '../OrgConsoleHeader';
import Tabs from './Tabs';
import SearchBoxLauncher from '../../TopNav/SearchBoxLauncher';
import FolderInfo from '../../ManageContent/FolderInfo';
import './style.css';

const Resources = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const flags = useFlags([FLAG_RESOURCES_READONLY]);
  const { getFlags } = useFlagsmith();
  // Redux
  const organization = useSelector((state) => state.organization);
  const resourceGroups = useSelector((state) => state.resourceGroups);
  const resourceGroup = useSelector((state) => state.resourceGroup);
  const currentUser = useSelector((state) => state.currentUser);

  // Groups
  const groupIds = resourceGroups?.groupIds || null;
  const groupTags = resourceGroups?.groupTags || null;
  // Params
  const searchParams = new URLSearchParams(window.location.search);
  const paramGroupTags = searchParams.get('groupTags');
  const paramGroupIds = searchParams.get('groupIds');
  const paramFileId = searchParams.get('fileId');
  // Organization
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  // User
  const userId = currentUser?.id || '';
  const role = currentUser?.role || [];
  // Resource Group
  const authorUserId = resourceGroup?.authorUserId || '';
  const groupTitle = resourceGroup?.title || '';

  const paramGroupIdsArray = useMemo(() => {
    if (!paramGroupIds) {
      return null;
    }

    return paramGroupIds.split(',');
  }, [paramGroupIds]);

  const paramGroupTagsArray = useMemo(() => {
    if (!paramGroupTags) {
      return null;
    }

    return paramGroupTags.split(',');
  }, [paramGroupTags]);

  useEffect(() => {
    getFlags();

    // Get Resource Group Tags
    dispatch(getResourceGroupTags({ orgId }));

    if (paramGroupIds) {
      const groupId = paramGroupIdsArray[paramGroupIdsArray.length - 1];

      dispatch(getResourceGroup({
        groupId,
        orgId
      })).then((response) => {
        if (entryOrgMatchesUserOrg(response?.orgId, currentUser?.orgId, ORG_CONSOLE_RESOURCES)) {
          // Get groups from url params for breadcrumb
          // Store in resourceGroups.list
          dispatch(getResourceGroups({
            orgId,
            groupIds: paramGroupIdsArray,
            groupTags: paramGroupTagsArray,
            select: ['id, title']
          }));
        }
      });
    } else {
      // When there are no groupIds param in the URL
      // get "Top Level" resource groups
      dispatch(getResourceGroups({
        orgId,
        groupTags: paramGroupTagsArray,
        // Conditionally include topLevel based on if in search or tags part of resources interface
        ...(Array.isArray(paramGroupTagsArray) && paramGroupTagsArray.length ? {} : { topLevel: true })
      }));
      // Reset Resource Group as we are at the "Top Level"
      dispatch(resetResourceGroup());
    }
  }, [
    currentUser?.orgId,
    dispatch,
    getFlags,
    orgId,
    paramGroupIds,
    paramGroupIdsArray,
    paramGroupTags,
    paramGroupTagsArray
  ]);

  const addGroup = () => {
    if (flags?.resources_readonly?.enabled && flags?.resources_readonly?.value) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_RESOURCES_READONLY_TITLE
      }));
      return;
    }

    dispatch(setCurrentModal({
      key: 'manageGroup',
      data: {
        type: 'resource',
        editMode: false
      }
    }));
  };

  const editGroup = (id) => {
    if (!id) {
      console.error('Missing parameter, id is required');
      return null;
    }

    fetchResourceGroup(id).then((group) => {
      if (!group?.id) {
        console.error(`Can't find group ${id}`);
        return null;
      }

      if (flags?.resources_readonly?.enabled && flags?.resources_readonly?.value) {
        dispatch(showFeatureFlagNoticeModal({
          modalTitle: NOTICE_RESOURCES_READONLY_TITLE
        }));
        return;
      }

      dispatch(setCurrentModal({
        key: 'manageGroup',
        data: {
          type: 'resource',
          editMode: true,
          group
        }
      }));
    });
  };

  const uploadFiles = (id) => {
    if (flags?.resources_readonly?.enabled && flags?.resources_readonly?.value) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_RESOURCES_READONLY_TITLE
      }));
      return;
    }

    dispatch(setCurrentModal({
      key: MODAL_KEY_UPLOAD_FILES,
      data: {
        contentId: id,
        contentType: 'resourceGroup',
        multiple: true,
        callback: (cdnFiles) => {
          dispatch(setResourceGroupFiles(cdnFiles));
        }
      }
    }));
  };

  const addLink = (id) => {
    if (flags?.resources_readonly?.enabled && flags?.resources_readonly?.value) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_RESOURCES_READONLY_TITLE
      }));
      return;
    }

    dispatch(setCurrentModal({
      key: MODAL_KEY_MANAGE_CDN_FILE,
      data: {
        modalTitle: 'Add Link',
        currentFile: {
          type: 'link'
        },
        currentFileId: null,
        contentId: id,
        contentType: 'resourceGroup'
      }
    }));
  };

  const addMaterial = () => {
    if (flags?.resources_readonly?.enabled && flags?.resources_readonly?.value) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_RESOURCES_READONLY_TITLE
      }));
      return;
    }

    dispatch(setSideSheet({
      key: SHEET_KEY_MATERIAL_ADD,
      data: {
        title: 'Add Material',
        confirmMoveAfterCreate: true,
        resourceGroup,
        groupIds
      }
    }));
  };

  const emptyDataOptions = [
    ...(canCreateResourceGroup(role, orgType) ? [{
      icon: ICON_FOLDER_OPEN,
      button: () => (
        <button
          data-cy="ResourcesList-AddFolder"
          type="button"
          className="btn btn-sm btn-outline-primary"
          onClick={addGroup}
        >
          Add Folder
        </button>
      )
    }] : []),
    ...(canCreateResource(role, orgType) && resourceGroup?.id ? [{
      iconComponent: () => (<FaFileAlt size={28} className='' />),
      button: () => (
        <button
          data-cy="ResourcesList-AddMaterialBtn"
          type="button"
          className="btn btn-sm btn-outline-primary text-nowrap"
          onClick={addMaterial}
        >
          Add Material
        </button>
      )
    }] : []),
    ...(canCreateResource(role, orgType) && resourceGroup?.id ? [{
      icon: ICON_UPLOAD,
      button: () => (
        <button
          data-cy="ResourcesList-UploadBtn"
          type="button"
          className="btn btn-sm btn-outline-primary"
          onClick={() => {
            uploadFiles(resourceGroup?.id);
          }}
        >
          Upload Files
        </button>
      )
    }] : []),
    ...(canCreateResource(role, orgType) && resourceGroup?.id ? [{
      icon: ICON_LINK,
      button: () => (
        <button
          data-cy="ResourcesList-AddLinkBtn"
          type="button"
          className="btn btn-sm btn-outline-primary"
          onClick={() => {
            addLink(resourceGroup?.id);
          }}
        >
          Add Link
        </button>
      )
    }] : [])
  ];

  const hiddenColumns = useMemo(() => {
    let cols = ['parentId', 'expander', 'similarity'];

    if (Array.isArray(groupTags)) {
      cols = [];
    }

    return cols;
  }, [groupTags]);

  const openFile = useCallback((resource) => {
    if (resource?.type === 'material') {
      dispatch(getResourceMaterial({ resourceId: resource?.id, orgId })).then(() => {
        dispatch(setSideSheet({
          key: SHEET_KEY_MATERIAL,
          className: 'MaterialSideSheet'
        })).then(() => {
          toast.dismiss('loadingResource');
        });
      });
    } else {
      dispatch(setCurrentModal({
        key: MODAL_KEY_RESOURCE_PREVIEW,
        data: {
          resource,
          modalTitle: resource?.name,
          iframeSrc: resource?.url
        }
      })).then(() => {
        toast.dismiss('loadingResource');
      });
    }
  }, [dispatch, orgId]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetResourceGroup());
    };
  }, [dispatch]);

  useEffect(() => {
    // https://www.npmjs.com/package/react-tooltip/v/4.5.1#3-tooltip-not-binding-to-dynamic-content
    ReactTooltip.rebuild();
  }, [resourceGroup?.list, resourceGroup?.cdnFiles]);

  useEffect(() => {
    if (paramFileId) {
      toast.info('Loading resource...', { toastId: 'loadingResource' });
      fetchResource(paramFileId).then((resource) => {
        if (paramGroupIds) {
          if (entryOrgMatchesUserOrg(resource?.orgId, currentUser?.orgId, ORG_CONSOLE_RESOURCES)) {
            openFile(resource);
          }
        } else {
          // Build URL
          const urlSearchParams = new URLSearchParams();

          urlSearchParams.set('groupIds', resource?.parentIds.join(','));
          urlSearchParams.set('fileId', paramFileId);
          history.replace(`${ORG_CONSOLE_RESOURCES}?${urlSearchParams}`);
        }
      });
    }
  }, [currentUser?.orgId, dispatch, history, openFile, paramFileId, paramGroupIds]);

  const sanitizedSearchGroupIds = useMemo(() => {
    let result = null;
    if (Array.isArray(groupIds) && groupIds.length > 0) {
      result = groupIds.slice(0).pop();

      return [result];
    }

    return result;
  }, [groupIds]);

  const hasFiles = useMemo(() => {
    return Array.isArray(resourceGroup?.cdnFiles) && resourceGroup?.cdnFiles.length > 0;
  }, [resourceGroup?.cdnFiles]);

  const embeddedFiles = useMemo(() => {
    let files = [];

    if (hasFiles) {
      files = resourceGroup?.cdnFiles.filter((item) => item.isEmbedded);
    }

    return files;
  }, [hasFiles, resourceGroup?.cdnFiles]);

  const hasEmbeddedFiles = useMemo(() => {
    return Array.isArray(embeddedFiles) && embeddedFiles.length > 0;
  }, [embeddedFiles]);

  if (!organization?.id || !currentUser?.id) {
    return (
      <Loading />
    );
  }

  if (!resourceGroups.fetched) {
    return <Loading text="Loading Resources..." />;
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="mb-5">
            <OrgConsoleHeader
              pageTitle="Resources"
              iconComponent={() => <FaPhotoVideo size={20} className='mr-2' />}
              route={ORG_CONSOLE_DASHBOARD}
            />

            <Tabs active="all">
              {resourceGroup?.id && (
                <div className="dropdown ml-2">
                  <button
                    className="btn btn-sm btn-dark dropdown-toggle d-flex align-items-center"
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    aria-label='Tools'
                  >
                    <span className='d-flex align-items-center'>
                      <FaToolbox className='mr-2 text-keppel' />
                      <span
                        className='mr-1'
                      >
                        Tools
                      </span>
                    </span>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right p-0 mt-1"
                  >
                    <div className='bg-dark text-white px-3 py-2'>
                      <span className='d-flex align-items-center'>
                        <FaToolbox className='mr-2 text-keppel' />
                        <span>
                          Context-Aware Tools
                        </span>
                      </span>
                    </div>
                    <button
                      className="dropdown-item p-3 border-bottom"
                      onClick={() => {
                        dispatch(setSideSheet({
                          key: SHEET_KEY_RESUME_SCREENER,
                          data: {
                            promptType: 'resumeScreener',
                            resourceGroupIds: sanitizedSearchGroupIds,
                            resourceGroupTitle: groupTitle
                          },
                          className: 'card-style'
                        }));
                      }}
                      type="button"
                    >
                      <span className='d-flex align-items-start'>
                        <FaMagnifyingGlassChart size={20} className='mt-2 text-primary' />
                        <span className='ml-2'>
                          <span className='font-weight-bold d-block'>
                            Resume Screener
                          </span>
                          <span
                            className='d-block'
                            style={{ width: '280px', whiteSpace: 'normal' }}
                          >
                            Find top candidates for an open role by analyzing resumes in this folder.
                          </span>
                        </span>
                      </span>
                    </button>

                    <button
                      className="dropdown-item p-3"
                      disabled
                      type="button"
                    >
                      <span className='d-flex align-items-start'>
                        <FaCode size={20} className='mt-1' />
                        <span className='ml-2'>
                          <span className='font-weight-bold d-block'>
                            Turbine Answers
                          </span>
                          <span
                            className='d-block'
                            style={{ width: '280px', whiteSpace: 'normal' }}
                          >
                            Embed Ask VELA on your website to help visitors find answers from files in this folder.
                          </span>
                        </span>

                      </span>
                    </button>

                  </div>
                </div>
              )}
            </Tabs>

            <div
              className="card-body border-left border-right border-bottom bg-white"
            >
              <Breadcrumb
                groupIds={groupIds}
                resourceGroup={resourceGroup}
                className='overflow-hidden'
              />

              {groupTags && (
                <div className="p-3 border-bottom d-flex align-items-center">
                  <h5 className="font-weight-bold mb-0 mr-2">
                    <FaTags />
                  </h5>

                  <GroupTagFilters
                    groupTags={groupTags}
                    groupIds={groupIds}
                    orgId={orgId}
                  />
                </div>
              )}

              <div className="card">
                <div
                  className="card-header p-0 d-flex align-items-center justify-content-between"
                >
                  <FolderInfo
                    className="h5 mb-0 d-flex align-items-center Resources-title-container ml-3"
                    resourceGroupTitle={groupTitle || 'All Resources'}
                    hasEmbeddedFiles={hasEmbeddedFiles}
                    embeddedFiles={embeddedFiles}
                    hasFiles={hasFiles}
                  />

                  <SearchBoxLauncher
                    className="in-card-header"
                    placeholder='Ask or Search...'
                    searchGroupIds={paramGroupIdsArray}
                    modalTitle="Search Resources"
                    searchContentTypes="resources"
                  />
                </div>

                <div
                  className="card-body p-0 overflow-content"
                >
                  <GroupsList
                    data={resourceGroup?.id ? resourceGroup?.list : resourceGroups?.list}
                    editMenu={canManageResourceGroups(role)}
                    editItem={editGroup}
                    addItem={addGroup}
                    uploadFiles={uploadFiles}
                    orgId={orgId}
                    groupIds={paramGroupIds ? paramGroupIds.split(',') : []}
                    groupTags={groupTags}
                    limit={1000}
                  />

                  {resourceGroup?.id && Array.isArray(resourceGroup?.cdnFiles) && resourceGroup?.cdnFiles.length > 0 && (
                    <div className='m-1'>
                      <CdnFiles
                        id="resource-integration-files"
                        contentId={resourceGroup?.id}
                        contentType="resourceGroup"
                        className="mb-0 border-0"
                        theme="light"
                        header={false}
                        files={resourceGroup.cdnFiles}
                        // fileUpload
                        hiddenColumns={hiddenColumns}
                        editMenu
                        removeFileCallback={() => {
                          dispatch(getResourcesByParentId(resourceGroup?.id));
                        }}
                      />
                    </div>
                  )}

                  {emptyDataOptions && emptyDataOptions.length !== 0 && (
                    <EmptyData
                      disabled={false}
                      className="m-3"
                      options={emptyDataOptions}
                      data={resourceGroup.list}
                    />
                  )}
                </div>
              </div>

              {resourceGroup?.id && canEditResourceGroup({ role, userId, authorUserId }) && (
                <button
                  className="btn-link float-left mt-2"
                  type="button"
                  title="Settings"
                  onClick={() => {
                    editGroup(resourceGroup?.id);
                  }}
                >
                  <span className='d-flex align-items-center'>
                    <FaCog className='text-ships-officer mr-1' />
                    <span>
                      Settings
                    </span>
                  </span>
                </button>
              )}

              {resourceGroup.id && canDeleteResourceGroup({ role, userId, authorUserId }) && (
                <DeleteResourceGroup
                  id={resourceGroup.id}
                  title={resourceGroup.title}
                />
              )}

            </div>

          </div>
        </div>
      </div>
      <ReactTooltip id="Resources" />
    </>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Resources);
