import React from 'react';
import Switch from '../../components/Switch';

const WidgetTabs = ({
  className = '',
  widgetTab,
  setWidgetTab,
  showIsFeaturedSwitch = false,
  isFeatured,
  onChangeIsFeatured
}) => {
  return (
    <div
      className={`d-flex justify-content-between align-items-center border-bottom ${className}`}
    >
      <div>
        <ul
          className="nav nav-tabs border-0 pl-3"
          style={{ zIndex: 1 }}
        >
          <li className="nav-item mr-2">
            <button
              className={`nav-link ${widgetTab === 'link' && 'active'}`}
              type="button"
              disabled={widgetTab === 'link'}
              onClick={() => {
                setWidgetTab('link');
              }}
            >
              Links
            </button>
          </li>
          <li
            className="nav-item"
            style={{
              display: window.location.pathname.includes('reorder') ? 'none' : 'block'
            }}
          >
            <button
              className={`nav-link ${widgetTab === 'embed' && 'active'}`}
              type="button"
              disabled={widgetTab === 'embed' || window.location.pathname.includes('reorder')}
              onClick={() => {
                setWidgetTab('embed');
              }}
            >
              Embeds
            </button>
          </li>
        </ul>
      </div>

      {showIsFeaturedSwitch && (
        <Switch
          id="widgetTabs-isFeatured"
          className="mb-1 bg-white border rounded p-2"
          label="Pinned"
          name="isFeatured"
          slim
          value={isFeatured}
          onChange={onChangeIsFeatured}
        />
      )}
    </div>
  );
};

export default WidgetTabs;
