import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { withAuthorization } from '../../Session';
import Loading from '../../Loading';
import DashboardKnowledgeCapture from './DashboardKnowledgeCapture';
import DashboardCourses from './DashboardCourses';
import DashboardKnowledgeManagement from './DashboardKnowledgeManagement';
import DashboardDocs from './DashboardDocs';
import MyMaterials from '../../Dashboard/MyMaterials';
import PaginationSupabase from '../../ManageContent/PaginationSupabase';
import './style.css';

const OrgDashboard = () => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const materials = useSelector((state) => state.materials);
  // Current User
  const userId = currentUser?.id || null;
  // Organization
  const orgId = organization?.id || null;

  const initialListConfig = {
    orgId,
    userId,
    limit: 5,
    page: 1,
    isDraft: true,
    // workflowType: null,
    order: '-createdAt'
  };
  const [listConfig, setListConfig] = useState(initialListConfig);

  useEffect(() => {
    document.body.classList.add('bkg-pattern-light');

    return function cleanup() {
      document.body.classList.remove('bkg-pattern-light');
    };
  }, []);

  if (!currentUser?.id || !organization?.id) {
    return <Loading />;
  }

  return (
    <>
      <div className='col-12 col-md-11 col-xl-9 m-auto'>

        <DashboardKnowledgeCapture />

        <div className='position-relative'>
          <MyMaterials
            title="My Resources"
            listConfig={listConfig}
            creatable
          />
          {Array.isArray(materials?.list) && materials?.list.length > 0 && (
            <PaginationSupabase
              items={materials?.list}
              pagination={materials?.pagination}
              page={materials?.pagination?.page}
              onClickNext={() => {
                setListConfig((prev) => ({
                  ...prev,
                  page: materials?.pagination?.page + 1
                }));
                // goToElem('root');
              }}
              onClickPrev={() => {
                setListConfig((prev) => ({
                  ...prev,
                  page: materials?.pagination?.page - 1
                }));
                // goToElem('root');
              }}
            />
          )}
        </div>

        <DashboardCourses />
        <DashboardKnowledgeManagement />
        <DashboardDocs />
      </div>
      <ReactTooltip html id="OrgDashboard" />
    </>
  );
};

const condition = (user) => !!user.uid;

export default withAuthorization(condition)(OrgDashboard);
