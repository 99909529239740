import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaCode, FaRobot } from 'react-icons/fa';
import { withAuthorization } from '../../components/Session';
import { ORG_CONSOLE_ASSISTANTS, SLASH } from '../../constants/routes';
import { getAssistant, resetAssistant } from '../../actions/Assistants';
import { canAccessAssistants } from '../../permissions/access';
import { canManageAssistant } from '../../permissions/manage';
import { handleOpenUserSideSheet } from '../../actions/SideSheets';
import withOrgConsole from '../../components/App/withOrgConsole';
import OrgConsoleHeader from '../../components/OrgConsole/OrgConsoleHeader';
import Loading from '../../components/Loading';
import DotMenu from './DotMenu';
import AgentImage from '../AssistantManage/AgentImages/AgentImage';
import Users from '../../components/ManageContent/Users';
import KeyValueItem from '../../components/KeyValueItem';

const Assistant = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const assistant = useSelector((state) => state.assistant);
  // Organization
  const orgId = organization?.id || null;
  // Assistant
  const assistantId = params?.assistantId || null;
  const embedUrls = assistant?.embedUrls || [];
  // Current User
  const role = currentUser?.role || [];

  useEffect(() => {
    if (assistantId) {
      dispatch(getAssistant(assistantId)).catch((error) => {
        console.error(error);
        toast.error(error.message);
      });
    }

    return function cleanup() {
      dispatch(resetAssistant());
    };
  }, [dispatch, organization?.id, assistantId]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetAssistant());
    };
  }, [dispatch]);

  const onClickTeamMember = (userId) => {
    dispatch(handleOpenUserSideSheet({
      userId,
      orgId,
      currentUserOrgId: currentUser?.orgId
      // route,
      // history
    }));
  };

  if (!canAccessAssistants(role)) {
    return <Redirect to={SLASH} />;
  }

  if (assistantId && !assistant?.id) {
    return (
      <Loading text='Loading Assistant...' />
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-lg-12">
          <div className="mb-5">
            <OrgConsoleHeader
              pageTitle="Assistants"
              iconComponent={() => <FaRobot />}
              route={ORG_CONSOLE_ASSISTANTS}
            />

            <div
              className="d-flex align-items-center justify-content-between shadow mt-3 rounded rounded-3 bg-white p-3"
            >
              <div
                className="d-flex align-items-center"
              >
                <AgentImage
                  className="mb-0"
                  imgSrc={assistant?.agentImage}
                  imgName={assistant?.name}
                  bkgColor={assistant?.colorBtnBkg}
                />
                <h4 className="m-0">{assistant?.name}</h4>
              </div>

              {canManageAssistant(role) && (
                <DotMenu assistantId={assistantId} />
              )}
            </div>

            <div className="card mt-3 mb-4">
              <div
                className="card-header d-flex align-items-center justify-content-between"
              >
                <div className='h6 m-0 font-weight-bold d-flex align-items-center'>
                  <FaCode />
                  <span className='ml-2'>
                    Embed Assistant
                  </span>
                </div>
              </div>
              <div className='card-body'>
                <div className='mb-3'>
                  <KeyValueItem
                    className='text-nowrap mb-0'
                    title="Assistant ID"
                    value={assistant?.id}
                  />
                </div>
                <p>
                  To use this Assistant on a web page, copy this code into the {'<body>'} of your HTML.
                </p>
                <textarea
                  className='form-control'
                  defaultValue={`<script async src="https://console.turbinelms.com/turbine-assistants.js" data-id="${assistant?.id}" data-version="1"></script>`}
                />
                <div className='mt-3'>
                  <div className='font-weight-bold'>
                    Authorized Web Pages:
                  </div>

                  {Array.isArray(embedUrls) && embedUrls.length > 0 && (
                    <div className='d-flex flex-wrap align-items-center'>
                      {embedUrls.map((url, i) => (
                        <div
                          key={`embedUrl-${i}`}
                          className='rounded bg-light border mr-2 px-2 py-1 mt-2'
                        >
                          {url}
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {assistant?.userId && (
                  <div className='mt-4'>
                    <div className="mb-2 font-weight-bold">
                      Created by:
                    </div>
                    <Users
                      userIds={[assistant?.userId]}
                      onClick={onClickTeamMember}
                    />
                  </div>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Assistant);
