import { pathOr } from 'ramda';
import { v4 as uuid } from 'uuid';
import sanitize from 'sanitize-filename';
import {
  ICON_FILE,
  ICON_FILE_EXCEL,
  ICON_FILE_IMAGE,
  ICON_FILE_PDF,
  ICON_LINK,
  ICON_RESOURCE
} from '@apprentage/constants';
import * as aws from './aws';
import {
  coerceWhiteSpacesToSingle, unCamelCase, hyphenToSpace, underscoreToSpace, plusToSpace
} from '../utils/filenames';
import { client, flattenItems } from './contentful';
import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';
import {
  embedDoc, queryEngine, summarizeText, vectorSearch
} from './openAi';
import { fetchDocuments } from './documents';
import {
  SET_MATERIAL_FILES, SET_NOTIFICATION_FILES, SET_PROJECT_FILES, SET_RESOURCE_GROUP_FILES
} from '../actions/types';

const table = 'resources';

export const fileType = (type = '') => {
  switch (type) {
    case 'material':
      return {
        name: 'material',
        icon: ICON_FILE
      };
    case 'link':
      return {
        name: 'link',
        icon: ICON_LINK
      };
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
      return {
        name: 'image',
        icon: ICON_FILE_IMAGE
      };
    case 'application/pdf':
      return {
        name: 'excel',
        icon: ICON_FILE_PDF
      };
    case 'application/vnd.ms-excel':
      return {
        name: 'excel',
        icon: ICON_FILE_EXCEL
      };
    case 'video/mp4':
      return {
        name: 'video',
        icon: ICON_RESOURCE
      };
    default:
      return {
        name: 'file',
        icon: ICON_FILE
      };
  }
};

/**
 *
 * @param {number} size in bytes
 */
export const getFileSize = (size) => {
  const kb = size / 1024;
  const mb = kb / 1024;
  const gb = mb / 1024;
  if (kb < 1024) {
    return `${Math.round(kb)} kB`;
  }
  if (mb < 1024) {
    return `${Math.round(mb)} mB`;
  }
  return `${Math.round(gb)} gB`;
};

export const downloadResource = ({ name, file, orgId }) => {
  return new Promise((resolve, reject) => {
    aws.downloadFile({
      file,
      name,
      orgId
    }).then((response) => {
      const url = pathOr(null, ['url'], response);
      resolve(url);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const fetchResources = async ({
  orgId,
  parentId,
  parentType,
  name,
  body,
  ids,
  order = 'name',
  select,
  limit = 1000
}) => {
  const params = {
    order,
    limit
  };

  if (orgId) {
    params.orgId = orgId;
    params['f.orgId[eq]'] = orgId;
  }

  if (parentId) {
    params['f.parentId[eq]'] = parentId;
  }

  if (parentType) {
    params['f.parentType[eq]'] = parentType;
  }

  if (name) {
    params['f.name[ilike]'] = name;
  }

  if (body) {
    params['f.body[ilike]'] = body;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};

export const fetchResource = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const updateResource = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    }).then((updatedResource) => {
      resolve(updatedResource);
    }).catch((error) => {
      console.error(`update ${table}`, error);
      reject(error);
    });
  });
};

export const createResource = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    }).then((newResource) => {
      resolve(newResource);
    }).catch((error) => {
      console.error(`create ${table}`, error);
      reject(error);
    });
  });
};

export const deleteResource = (id) => {
  return new Promise((resolve, reject) => {
    deleteSupabaseEntry({
      data: { id },
      table
    }).then((deletedResource) => {
      resolve(deletedResource);
    }).catch((error) => {
      console.error(`delete ${table}`, error);
      reject(error);
    });
  });
};

export const fetchResourcesByVectorText = ({
  orgId,
  locationId,
  userId,
  saveSearch,
  searchText,
  contentTypes,
  prompt,
  parentType,
  searchGroupIds,
  count
}) => {
  return new Promise((resolve, reject) => {
    vectorSearch({
      orgId,
      locationId,
      userId,
      parentType,
      ...(searchGroupIds ? { parentIds: searchGroupIds } : {}),
      contentTypes,
      saveSearch,
      ...(searchText ? { searchText } : {}),
      ...(prompt ? { prompt } : {}),
      count
    }).then((vecResponse) => {
      // Response: [{
      //   id: (embeddingId),
      //   refId: (resourceId),
      //   similarity: (cosign similarity score)
      // }, {...}]
      if (Array.isArray(vecResponse?.items) && vecResponse?.items?.length) {
        const ids = vecResponse.items.reduce((acc, curr) => {
          if (!acc.includes(curr.id)) {
            // Make sure ids are unique
            acc.push(curr.id);
          }
          return acc;
        }, []);
        const refIds = vecResponse.items.reduce((acc, curr) => {
          if (!acc.includes(curr.refId)) {
            // Make sure refIds are unique
            acc.push(curr.refId);
          }
          return acc;
        }, []);
        fetchDocuments({
          orgId,
          ids,
          select: ['rawText', 'id', 'refId', 'pageNumber', 'metadata'],
          limit: count
        }).then((responseDocs) => {
          fetchResources({
            orgId,
            ids: refIds
          }).then((response) => {
            const vectorNodes = [];
            const groupedEmbeddings = responseDocs.items.reduce((acc, curr) => {
              if (acc[curr.refId] === undefined) {
                acc[curr.refId] = [];
              }
              const resource = response.items.find((r) => r.id === curr?.refId);
              const item = vecResponse.items.find((vec) => vec.id === curr?.id);

              acc[curr.refId].push({
                ...curr,
                similarity: item?.similarity,
                resource
              });

              vectorNodes.push({
                ...curr,
                similarity: item?.similarity,
                resource
              });

              return acc;
            }, {});

            const vectorFiles = response?.items.reduce((acc, curr) => {
              const item = vecResponse.items.find((vec) => vec.refId === curr?.id);
              acc.push({
                ...curr,
                similarity: item?.similarity,
                embeddings: groupedEmbeddings[curr?.id]
              });

              return acc;
            }, []);

            resolve({
              vectorSearchId: vecResponse?.searchId,
              vectorFileIds: refIds,
              vectorFiles,
              vectorNodes,
              resourceVecIds: vecResponse?.resourceVecIds
            });
          }).catch((error) => {
            console.error(error);
            reject(error);
          });
        });
      } else {
        resolve({ vectorFiles: [] });
      }
    });
  });
};

export const fetchResourcesByTitle = ({ orgId, name }) => {
  return new Promise((resolve, reject) => {
    fetchResources({
      orgId,
      name,
      parentType: 'resourceGroup'
    }).then((response) => {
      resolve({ cdnFiles: response?.items });
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const fetchResourcesByBody = ({ orgId, body }) => {
  return new Promise((resolve, reject) => {
    fetchResources({
      orgId,
      body,
      parentType: 'resourceGroup'
    }).then((response) => {
      resolve({ cdnFiles: response?.items });
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const filesInGroupsMatchingSearch = ({ searchValue, groups }) => {
  const files = [];
  const filenameLowerCase = searchValue.toLowerCase();

  groups.forEach((group) => {
    if (group.cdnFileNames) {
      group.cdnFileNames.forEach((cdnFilename) => {
        const cdnFilenameLowerCase = cdnFilename.toLowerCase();

        if (cdnFilenameLowerCase.includes(filenameLowerCase)) {
          const fileId = cdnFilename.split(':')[0];
          const file = group.integration.cdnFiles.find((f) => f.id === fileId);

          if (file) {
            files.push(file);
          }
        }
      });
    }
  });

  return files.length ? files : null;
};

// TODO this needs to change to hit Supabase Resources
export const fetchGroupsByFilename = ({ orgId, searchValue }) => {
  return new Promise((resolve, reject) => {
    const config = {
      content_type: 'group',
      'fields.cdnFileNames[match]': searchValue,
      'fields.orgId': orgId
    };

    return client.getEntries(config).then(({ items }) => {
      const resourceGroups = items.length ? flattenItems(items) : null;
      const cdnFiles = resourceGroups ? filesInGroupsMatchingSearch({ searchValue, groups: resourceGroups }) : null;

      resolve({
        value: searchValue,
        groups: resourceGroups,
        cdnFiles
      });
    }).catch((error) => {
      reject(error);
    });
  });
};

export const autoGenerateTitleAndBody = async ({
  isEmbedded, orgId, id, url
}) => {
  return new Promise((resolve, reject) => {
    if (!isEmbedded) {
      embedDoc({
        refTable: 'resources',
        orgId,
        id,
        url
      }).then((embeddingResponse) => {
        if (embeddingResponse?.error) {
          reject(embeddingResponse?.error);
          return;
        }

        queryEngine({
          promptType: 'summarize',
          orgId,
          refId: id
        }).then((response) => {
          const answer = JSON.parse(response?.data?.answer);
          resolve(answer);
        }).catch((error) => {
          console.error(error);
          reject(error);
        });
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    } else {
      queryEngine({
        promptType: 'summarize',
        orgId,
        refId: id
      }).then((response) => {
        const answer = JSON.parse(response?.data?.answer);
        resolve(answer);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    }
  });
};

// TODO this needs to change to hit Supabase Resources
export const generateUniqueResourceId = async ({ orgId }) => {
  const uniqueId = uuid();
  const response = await fetchGroupsByFilename({ orgId, searchValue: uniqueId });
  const cdnFiles = pathOr([], ['cdnFiles'], response);

  if (cdnFiles.length > 0) {
    generateUniqueResourceId({ orgId });
  } else {
    return uniqueId;
  }
};

export const coerceToResourceName = ({
  str,
  fixCamelCase = false,
  fixHyphens = false,
  fixUnderScores = false,
  fixPlus = false
}) => {
  let output = str;

  output = sanitize(output);

  if (fixCamelCase) {
    // This flag is needed to prevent unexpected UX
    // while manipulating the str in an input field
    output = unCamelCase(output);
  }

  if (fixHyphens) {
    output = hyphenToSpace(output);
  }

  if (fixUnderScores) {
    output = underscoreToSpace(output);
  }

  // output = fileNameFriendly(output);

  // output = adhereToOSFilenames(output);
  if (fixPlus) {
    output = plusToSpace(output);
  }

  output = coerceWhiteSpacesToSingle(output);

  return output.trimEnd();
};

export const summarizeRawText = ({
  topic,
  rawText = '',
  orgId = '',
  maxCharCount = 200
  // 300 > 445
  // 250 > 300
  // 200 > 245
}) => {
  return new Promise((resolve, reject) => {
    summarizeText({
      ...(topic ? { topic } : {}),
      rawText,
      orgId,
      maxCharCount
    }).then((response) => {
      resolve(response?.data);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const previewFileTypes = (type) => {
  const acceptableFileTypes = [
    'application/pdf',
    'video/mp4'
  ];

  if (!type) {
    return false;
  }

  if (acceptableFileTypes.includes(type)) {
    return true;
  }

  if (type.includes('image') && type !== 'image/vnd.adobe.photoshop') {
    // 'image/jpg', 'image/jpeg', 'image/png', 'image/gif'
    return true;
  }

  return false;
};

export const actionFromParentType = (parentType) => {
  // Construct Dispatch Data based on parentType (supabase)
  switch (parentType) {
    case 'projects':
      return SET_PROJECT_FILES;
    case 'notifications':
      return SET_NOTIFICATION_FILES;
    case 'materials':
      return SET_MATERIAL_FILES;
    case 'resourceGroup': // TODO change to resourceGroups
      return SET_RESOURCE_GROUP_FILES;
    default:
      return SET_RESOURCE_GROUP_FILES; // SET_CURRENT_ENTRY_FILES
  }
};
